import React, { useRef, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../../components/Text";
import CustomInput from "../../../../components/CustomInput";
import { defaultColor } from "../../../../utils/casaWebUtils";
import { CustomDropdown } from "../../../../components/CustomDropdown/CustomDropdown";
import {
  iconPickerTemplate,
  listOptionTemplate,
  listOptionTemplateWithCount,
  selectedIconTemplate,
  valueTemplate,
} from "../../../../utils/dropdownUtils";
import { CustomIconPicker } from "../../../../components/CustomIconPicker/CustomIconPicker";
import { iconList } from "../../../../utils/iconList";
import {
  casaWebConstants,
  casaWebWidgetsConstants,
  globalConstants,
  imageRecommendations,
  quicklauncherConstants,
} from "../../../../constants/globalConstant";
import ControlledMultiselect from "../../../../components/LSQMultiSelect/ControlledMultiselect";
import LsqSwitch from "../../../../components/LsqSwitch";
import {
  updateBannerWidgetInLayout,
  updateEntityMetaData,
  updateWebWidget,
} from "../../../../reducers/webHomepageSlice";
import { GET_DATA_FROM_API_FOR_CASA_WEB } from "../../../../api/webHompageConfigurationServices";
import TaskFilter from "../../CasaWebWidgets/TaskFilterComponent/TaskFilter";
import SmartViewFilter from "../../CasaWebWidgets/SmartViewFilterComponent/SmartViewFilter";
import FileInput from "../../CasaWebWidgets/FileUploadWidget/FileInput";
import CustomRadioGroup from "../../../../components/CustomRadioGroup";
import ColorPicker from "../../CasaWebWidgets/ColorPickerWidget/colorPicker";
import SetThresholds from "../../CasaWebWidgets/ThresholdsWidget/ThresholdsWidget";
import { webWidgetTypes } from "../../../../constants/globalEnums";
import LsqMobileNavigator from "../../../../components/LsqMobileNavigator";
import { apiGetWebLauncehBarMetaData } from "../../../../reducers/webLaunchbarSlice";
import Recommendations from "./../../../../components/Recommendations/index";
import { getEntityCountMap } from "./helper";
import RearrangeIconLauncherItems from "./components/RearrangeIconLauncherItems";

const FieldComponentsParser = ({
  field,
  form,
  coverPageData = {},
  widgetType,
  mode,
}) => {
  const dispatch = useDispatch();

  // const selectedRadioValue = coverPageData?.customRadio === "color" ? 'color' : 'image'
  const { selectedWidgetLanguage, layout, casaWebLimits } = useSelector(
    (state) => state.webHomepage
  );

  const entityCountMap = getEntityCountMap(layout, casaWebLimits);
 
  const isOptionDisabled = (option) => {
    const entityType = option?.id;
    const maxLimit = casaWebLimits?.entitiesLimit?.[entityType];
    const usedCount = entityCountMap?.[entityType];
    const isDisabled = entityType && typeof(maxLimit) === casaWebConstants.NUMBER && typeof(usedCount) === casaWebConstants.NUMBER && usedCount >= maxLimit;

    return !!(isDisabled)
  }

  const decideItemTemplate = () => {
    const isAnyRestriction = Object.keys(entityCountMap).length;
    const isEntityField = field?.fieldName === casaWebConstants.ENTITY;

    if(isAnyRestriction && isEntityField){
      return (option) => listOptionTemplateWithCount(option, entityCountMap, casaWebLimits);
    }

    return listOptionTemplate;
  }
  
  const updateWidgetConfig = useCallback(
    (fieldName, value) => {
      dispatch(
        updateWebWidget({
          id: selectedWidgetLanguage?.id,
          config: {
            ...structuredClone(form.getValues()),
            [fieldName]: value,
          },
        })
      );
    },
    [dispatch, form, selectedWidgetLanguage]
  );

  const renderIconPicker = ({ field, form, iconPickerRef }) => {
    const [iconPanelWidth, setIconPanelWidth] = useState(0);
    return (
      <CustomIconPicker
        key={field.id}
        id="icon-list"
        fieldName={field.fieldName || "icon"}
        control={form.control}
        className="w-full text-base lsq-input lsq-icon-picker"
        options={iconList}
        optionLabel="name"
        filter
        isMandatory={field.isMandatory || false}
        filterBy="name"
        resetFilterOnHide
        filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
        placeholder="lsq_change_image"
        valueTemplate={selectedIconTemplate}
        itemTemplate={iconPickerTemplate}
        label={quicklauncherConstants.ICON}
        errors={form.formState.errors}
        panelClassName="lsqIconPickerPanel"
        onShow={() => {
          setIconPanelWidth(iconPickerRef.current?.offsetWidth);
        }}
        panelStyle={{ width: iconPanelWidth }}
        containerStyle={{ width: `${field?.width+8}%` }}
        onChange={(e) => {
          form.setValue(field.fieldName, e.target.value);
          updateWidgetConfig(field.fieldName, e.target.value);
        }}
      />
    );
  };

  const renderTextInput = ({ field, form }) => {
    return (
      <div className={`flex gap-2 flex-column`} key={field.id}>
        {field?.title && (
          <Text type="T4B" color="var(--N400)">
            {field.title}
          </Text>
        )}
        <CustomInput
          key={field.id}
          control={form.control}
          errors={form.formState.errors}
          fieldName={field.fieldName || "inputText"}
          maxLength={field.max}
          className="lsq-input-bold"
          label={field.label}
          isMandatory={field.isMandatory || false}
          data-testid={field.fieldName}
          onBlur={(e) => updateWidgetConfig(field.fieldName, e.target.value)}
          containerStyle={{ width: `100%` }}
          pattern={
            field.regex && {
              value: new RegExp(field.regex?.value),
              message: field.regex?.message,
            }
          }
        />
      </div>
    );
  };

  const renderDropdown = ({ field, form, hidden, ...props }) =>
    !hidden && (
      <CustomDropdown
        label={field.label}
        errors={form.formState.errors}
        className={`w-full text-base lsq-input`}
        fieldName={field.fieldName || "dropdown"}
        isMandatory={field.isMandatory || false}
        control={form.control}
        options={field.options}
        optionDisabled={isOptionDisabled}
        placeholder="Select"
        optionLabel="name"
        itemTemplate={decideItemTemplate()}
        valueTemplate={valueTemplate}
        {...props}
      />
    );

  const renderMultiSelectDropdown = ({ field, form }) => (
    <ControlledMultiselect
      label={field.label}
      errors={form.formState.errors}
      className="w-full text-base lsq-input"
      isMandatory={field.isMandatory || false}
      fieldName={field.fieldName || "multiSelectDropdown"}
      control={form.control}
      options={field.options}
      optionLabel="name"
      placeholder="Select"
      itemTemplate={listOptionTemplate}
      panelClassName="lsq-multiselect-panel"
      selectionLimit={10}
      maxSelectedLabels={-1}
      id={field.fieldName}
      onChange={(e) => form.setValue(field.fieldName, e.target.value)}
    />
  );

  const renderCustomRadioGroup = ({ field, form }) => {
    const defaultValue =
      coverPageData.customRadio === "color" ? "color" : "image";

    useEffect(() => {
      form.setValue("customRadio", defaultValue);
      if (defaultValue === "color" && coverPageData.selectedColor) {
        form.setValue("selectedColor", coverPageData.selectedColor);  
      } else if (defaultValue === casaWebConstants.IMAGE && coverPageData?.image) {
        form.setValue(casaWebConstants.IMAGE, coverPageData.image);
      }
    }, [defaultValue, coverPageData, form]);

    return (
      <div>
        <CustomRadioGroup
          label={field.label}
          options={[
            { key: "Solid color", value: "color" },
            { key: "Image", value: "image" },
          ]}
          radioName={field.fieldName || "customRadio"}
          control={form.control}
          defaultValue={defaultValue}
        />
        {defaultValue === "color" ? (
          <ColorPicker
            colors={defaultColor}
            control={form.control}
            name="selectedColor"
          />
        ) : (
          <div className="p-3">
            {renderFileInput({ field, form, coverPageData })}
          </div>
        )}
      </div>
    );
  };

  const renderFileInput = ({ field, form, coverPageData }) => {
    form.register(field.fieldName, { required: field.isMandatory });
    return (
      <div className="flex flex-column gap-1">
        <FileInput field={field} form={form} coverPageData={coverPageData} />
        <Recommendations
          heading={
            coverPageData
              ? imageRecommendations.coverPage.header
              : imageRecommendations.banner.header
          }
          points={
            coverPageData
              ? imageRecommendations.coverPage.points
              : imageRecommendations.banner.points
          }
        />
      </div>
    );
  };

  const renderSwitchController = ({ field, form }) => {
    // Use form.watch to track changes to the switch's value
    const watchEntity = form.watch(field.fieldName);

    useEffect(() => {
      // Only set the form value if it actually changes
      if (watchEntity !== undefined) {
        dispatch(updateBannerWidgetInLayout(watchEntity))
        form.setValue("showFrame", watchEntity);
      }
    }, [watchEntity, form]);

    return (
      <LsqSwitch
        control={form.control}
        label={field.label}
        switchName={field.fieldName || "switch"}
        textColor={"var(--N90)"}
        data-testid={field.fieldName}
        style={{ justifyContent: "space-between", marginRight: "0.3rem" }}
      />
    );
  };

  const renderRowComponent = ({ field, form }) => {
    const iconPickerRef = useRef(null);
    return (
      <div className="flex w-full gap-1" ref={iconPickerRef}>
        {field.components?.map((component) => {
          const renderField = fieldRenderers[component.type] || (() => null);
          return (
            renderField({ field: component, form, iconPickerRef }) || <></>
          );
        })}
      </div>
    );
  };

  const getSubMenu = async (navigationItem) => {
    const metaDataLink = navigationItem._links?.subMenu?.href;
    const { data } = await GET_DATA_FROM_API_FOR_CASA_WEB(metaDataLink);
    if (data === undefined) return;
    return data;
  };

  const renderEntitySelection = ({ field, form }) => {
    const { entityMetaData } = useSelector((state) => state.webHomepage);
    const watchEntity = form.watch(field.fieldName);
    const watchSubField = form.watch("taskStatus"); // Watch the subfield value
    useEffect(() => {
      // Clear dependent fields when watchEntity changes
      form.setValue(
        "taskStatus",
        widgetType === webWidgetTypes.SPEEDO_METER ||
          widgetType === webWidgetTypes.PROGRESS_BAR
          ? {
              id: "completed",
              name: "Completed",
            }
          : null
      ); // Reset taskStatus before setting new values
      // form.setValue("thresholds", null);

      if (watchEntity && !entityMetaData[watchEntity.id]) {
        getSubMenu(watchEntity).then((payload) => {
          dispatch(
            updateEntityMetaData({
              ...entityMetaData,
              [watchEntity.id]: payload,
            })
          );
        });
      }

      // Check if `selectedWidgetLanguage` has `taskStatus` value and set it
      if (watchEntity && selectedWidgetLanguage?.config?.taskStatus) {
        form.setValue("taskStatus", selectedWidgetLanguage.config.taskStatus);
      }
    }, [watchEntity, selectedWidgetLanguage]);
    useEffect(() => {
      if (field && field.fieldName === "entity" && field.options.length === 1)
        form.setValue(field.fieldName, field.options[0]);
    }, [field]);
    return (
      <div className="flex flex-column gap-2">
        {renderDropdown({
          field,
          form,
          hidden:
            field && field.fieldName === "entity" && field.options.length === 1,
        })}
        {watchEntity && (
          <>
            <div style={{borderTop:' 1px solid var(--N30)'}}/>
            {watchEntity?.id === casaWebWidgetsConstants.TASKS && (
              <TaskFilter
                form={form}
                metaData={entityMetaData}
                widgetType={widgetType}
              />
            )}
            {watchEntity?.id === casaWebWidgetsConstants.SMART_VIEWS && (
              <>
                <SmartViewFilter form={form} metaData={entityMetaData} />
              </>
            )}
            {(watchEntity?.id === casaWebWidgetsConstants.SMART_VIEWS ||
              (watchSubField?.id === "completed" &&
                field?.includeSetThreshold)) && (
              <SetThresholds thresholdForm={form} />
            )}
          </>
        )}
      </div>
    );
  };

  const renderLSQNavigator = ({ field, form }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(true);
    const [edit, setEdit] = useState(mode === globalConstants.UPDATE);
    const { launchBarFormMetaData } =
      useSelector((state) => state.webLaunchBar) || {};
    const [navigationList, setNavigationList] = useState(
      field?.showExternalLink
        ? [
            {
              name: "External Link",
              items: [{ id: "ExternalLink", name: "-----" }],
            },
            {
              name: "Internal Navigation",
              items: [],
            },
          ]
        : [
            {
              name: "Internal Navigation",
              items: [],
            },
          ]
    );
    useEffect(() => {
      const fetchMetaData = async () => {
        const formFilterList = field?.options || [];
        let filteredMenu;
        if (launchBarFormMetaData?.menu?.length > 0)
          filteredMenu = launchBarFormMetaData?.menu.filter((item) =>
            formFilterList.includes(item.id)
          );
        else {
          let data = await dispatch(apiGetWebLauncehBarMetaData());
          filteredMenu = data.payload.menu.filter((item) =>
            formFilterList.includes(item.id)
          );
        }

        setNavigationList((prevList) => {
          const updatedList = [...prevList];
          updatedList[1].items = filteredMenu;
          return updatedList;
        });
        setIsLoading1(false);
      };

      fetchMetaData();
    }, [dispatch]);
    if (isLoading1) return;
    return (
      <div className={`flex gap-2 flex-column`} key={field.id}>
        <LsqMobileNavigator
          formValues={form}
          mobileNavigationMenu={navigationList}
          unregister={form.unregister}
          edit={edit}
          setEdit={setEdit}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          mode={mode}
          application="WEB"
          displayExternalLinkOpeningOptions={false}
        />
      </div>
    );
  };

  const fieldRenderers = {
    ICON: renderIconPicker,
    TEXT: renderTextInput,
    DROPDOWN: renderDropdown,
    FILE: renderFileInput,
    SWITCH: renderSwitchController,
    MULTI_SELECT_DROPDOWN: renderMultiSelectDropdown,
    ROWCOMPONENT: renderRowComponent,
    ENTITYSELECTION: renderEntitySelection,
    RADIOGROUP: renderCustomRadioGroup,
    LSQ_NAVIGATOR: renderLSQNavigator,
    LSQ_ICON_LAUNCHER: RearrangeIconLauncherItems
  };

  const renderField = fieldRenderers[field.type] || (() => null);
  return renderField({ field, form }) || <></>;
};

export default FieldComponentsParser;
