import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Joyride from "react-joyride";
import { ReactComponent as SearchIcon } from "../../assets/svgIcons/search.svg";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomInputV2 from "../../components/CustomInputV2";
import CoachTooltip from "../../components/Coach/CoachTooltip";
import { teamsViewSteps } from "../../components/Coach/coachSteps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Modal from "../../components/Modal";
import AddCard from "../../components/AddCard";
import {
  searchTeams,
  sortTeams,
  teamsViewSortItems,
} from "../../utils/casaUtils";
import {
  ROUTES_PATH,
  casaConstants,
  dashboardCardConstants,
  globalConstantValues,
  globalConstants,
} from "../../constants/globalConstant";
import {
  changeSelectedHomepage,
  getTeamsAndTeamsAssignments,
  apiUpdateUserPreferences,
  addToastMessage,
} from "../../reducers/homePageSlice";
import {
  countLimit,
  hierarchyToLinear,
} from "../../utils/globalUtils";

import TeamsViewMenuCard from "../../components/TeamsViewMenuCard";
import ToastMessage from "../../components/ToastMessage";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import TeamsContainer from "./TeamsContainer";
import { InlineDropdown } from "../../components/InlineDropdown";
import { apiAddWebHomepage, apiGetAllTeamsWebDashboardsV2, apiGetAllWebDashboards } from "../../reducers/webHomepageSlice";
import { apiAddWebLaunchBar, apiGetAllTeamsWebLaunchBarsV2, apiGetAllWebLaunchBars, apiGetWebLaunchBar } from "../../reducers/webLaunchbarSlice";
import { getAllTeamsDataForTable, getTeamIdBasedMapping } from "./utility";
import { defaultColorPaylod } from "../../utils/casaWebUtils";
import useToast from "../../hooks/useToast";

const Teams = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast(addToastMessage);
  const teamsViewSortState = useState(teamsViewSortItems[0].label);
  const searchState = useState("");
  const [, setPreviewPanel] = useState({
    state: false,
    data: null,
  });
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const { toastMessages, isHomepageLoading } = useSelector(
    (state) => state.homepage
  );
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const { isMenuBuilderLoading } = useSelector(
    (state) => state.menuBuilder
  );
  
  const loading = isMenuBuilderLoading || isHomepageLoading;
  
  const { userPreferences } = useSelector(
    (state) => state.homepage
  );
  const { teamsCoachMarksVisited, sideNavigationCoachMarksVisited } =
    userPreferences;

  const linearTeams = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const teamsMappedWithTeamId = useMemo(() => {
    return Object.fromEntries(linearTeams.map((team) => [team.teamId, team]));
  }, [linearTeams]);


  const dashboardsById = useSelector((state) => state.webHomepage?.dashboardById);
  const launchersById = useSelector((state) => state.webLaunchBar?.dashboardById);
  const { defaultLauncher, teamLaunchers } = useSelector((state) => state.webLaunchBar?.teamLaunchBarsV2);
  const { defaultDashboards, teamDashboards: webTeamDashboards } = useSelector((state) => state.webHomepage?.teamWebDashboardsV2);
  const dashboardsbyTeamId = getTeamIdBasedMapping(webTeamDashboards);
  const launchersbyTeamId = getTeamIdBasedMapping(teamLaunchers);
  const tableData = getAllTeamsDataForTable(
    linearTeams,
    dashboardsbyTeamId,
    launchersbyTeamId,
    defaultDashboards,
    defaultLauncher
  );

  let filterAndSortedTeams = searchTeams(
    tableData.teamsData,
    searchState[0],
    teamsMappedWithTeamId
  );

  if (teamsViewSortState[0] === casaConstants.RECENTLY_MODIFIED) {
    filterAndSortedTeams = filterAndSortedTeams.filter(
      (item) => item.dashboards.length !== 0 || item.launcher !== undefined
    );
  }

  filterAndSortedTeams = sortTeams(
    filterAndSortedTeams,
    teamsViewSortState[0],
    teamsMappedWithTeamId
  );

  filterAndSortedTeams = [
    tableData.defaultTeam,
    ...filterAndSortedTeams,
  ];

  useEffect(() => {
    dispatch(getTeamsAndTeamsAssignments());
    dispatch(apiGetAllTeamsWebDashboardsV2());
    dispatch(apiGetAllWebDashboards());
    dispatch(apiGetAllTeamsWebLaunchBarsV2());
    dispatch(apiGetAllWebLaunchBars());
  }, []);

  const teamTemplate = (data) => {
    return (
      <Text
        type="T1B"
        color="var(--text-primary)"
        className={"overflow-wrap-anywhere"}
      >
        {data.teamLabel}
      </Text>
    );
  };


  const navigateCreateNewDashBoard=()=>{
    dispatch(apiAddWebHomepage({ postBody: defaultColorPaylod }))
      .then((response) => {
        if (response.type.includes(globalConstants.FULFILLED)) {
          const dashboardInfo = response?.payload;
          if (dashboardInfo) {
            localStorage.setItem('currentDashboardInfo', JSON.stringify(dashboardInfo));
            navigate(ROUTES_PATH.webHomepage);
          }
        } else {
          toast.warn(
            `Maximum ${countLimit.WEB_HOMEPAGES_COUNT} homepages can be added`
          );
        }
      });
  }

  const navigateCreateNewLauncher = () => {
    dispatch(apiAddWebLaunchBar({ postBody: defaultColorPaylod }))
      .then((response) => {
        const dashboardInfo = response?.payload;
        if (dashboardInfo) {
          localStorage.setItem(
            "currentLaunchBarInfo",
            JSON.stringify(dashboardInfo)
          );
          navigate(ROUTES_PATH.launchBarHomePage);
        }
      })
      .catch((error) => {
        console.error("Error creating new dashboard:", error);
      });
  };

  const homepagesTemplate = (data) => {
    const homepages = data.dashboards;
    const clonedhomepages = homepages.filter(
      (homepageId) => dashboardsById[homepageId] !== undefined
    );
    return (
      <div className="flex gap-1">
        <TeamsContainer
          dragAndDrop={true}
          isScrollable={true}
          homepages={homepages}
          homepagesById={dashboardsById}
          teamId={data.teamId}
          setPreviewPanel={setPreviewPanel}
          setDialog={setDialog}
        ></TeamsContainer>
        {clonedhomepages?.length <
          globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD && (
            <AddCard
              cardName={dashboardCardConstants.ADD_PAGE}
              cardClickHandler={() => {
                navigateCreateNewDashBoard("Homepages View");
              }}
            ></AddCard>
          )}
      </div>
    );
  };

  const handleLauncherCardClick = (id) => {
    dispatch(changeSelectedHomepage(id));
    dispatch(apiGetWebLaunchBar(id))
      .then((response) => {
        const dashboardData = response?.payload;
        if (dashboardData) {
          localStorage.setItem('currentLaunchBarInfo', JSON.stringify(dashboardData));
          navigate(ROUTES_PATH.launchBarHomePage);
        }
      })
      .catch((error) => {
        console.error('Error getting dashboard data:', error);
      });
  };

  const launcherTemplate = (data) => {
    const menuId = data.launcher;
    return menuId ? (
      <TeamsViewMenuCard
        menu={launchersById[menuId]}
        cardClickHandler={() => handleLauncherCardClick(menuId)}
      ></TeamsViewMenuCard>
    ) : (
      <AddCard
        cardName={"Add Launcher"}
        cardClickHandler={() => {
          navigateCreateNewLauncher("Homepages View");
        }}
      ></AddCard>
    );
  };

  const onCoachSkippedOrFinished = () => {
    const payload = {
      teamsCoachMarksVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };

  return (
    <div
      className="flex h-screen w-screen align-items-center p-3"
      data-testid="teams-data-view"
    >
      {(sideNavigationCoachMarksVisited && !loading) && (
        <Joyride
          tooltipComponent={(props) => (
            <CoachTooltip
              {...props}
              onCoachSkippedOrFinished={onCoachSkippedOrFinished}
            />
          )}
          continuous
          run={!teamsCoachMarksVisited}
          showProgress
          showSkipButton
          steps={teamsViewSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
        />
      )}
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden ml-7 ${styles["teams-tabular-view"]}`}
      >
        <div
          className={`flex w-full align-items-center justify-content-between p-3`}
        >
          <Text type="T1B" color="var(--text-primary)">
            {globalConstants.TEAM_MANAGEMENT}
          </Text>
          <div className={`flex align-items-center gap-2`}>
            <InlineDropdown
              items={teamsViewSortItems}
              selectedLabel={teamsViewSortState[0]}
              setSelectedLabel={teamsViewSortState[1]}
              style={{ width: "fit-content" }}
            ></InlineDropdown>
            <CustomInputV2
              style={{ width: "20rem", height: "2.25rem" }}
              placeholder={"Search Teams"}
              value={searchState[0]}
              onChange={(e) => searchState[1](e.target.value)}
              LeftIcon={SearchIcon}
            />
          </div>
          <div className={`toast_container`}>
            <ToastMessage toastMessages={toastMessages} />
          </div>
        </div>
        <div className={`flex w-full h-full flex-column overflow-scroll`}>
          {loading ? (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          ) : (
            <div style={{ height: "0.2rem" }} />
          )}
          <DataTable
            value={filterAndSortedTeams}
            className="lsq-data-table"
            responsiveLayout="scroll"
            paginator
            rows={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Column
              field="teamLabel"
              header={globalConstants.TEAMS}
              style={{ width: "25%", minWidth: "10rem" }}
              body={teamTemplate}
              headerClassName="coach-teams-column"
            ></Column>
            <Column
              field="dashboards"
              style={{
                width: "50%",
                border: "1px solid #dee2e6",
                borderWidth: "0 1px  1px 1px",
              }}
              header={globalConstants.HOMEPAGES}
              body={homepagesTemplate}
              headerClassName="coach-homepages-column"
            ></Column>
            <Column
              field="launcher"
              style={{ width: "25%" }}
              header={globalConstants.LAUNCHERS}
              body={launcherTemplate}
              headerClassName="coach-menus-column"
            ></Column>
          </DataTable>
        </div>
      </div>

      <Modal
        state={dialog.state}
        backgroundStyle={{
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(22px)",
        }}
      >
        {dialog.dialogBox}
      </Modal>
    </div>
  );
};
export default Teams;
