import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Slider } from 'primereact/slider';
import { ReactComponent as Rotate } from "../../../../assets/svgIcons/rotate.svg";
import { ReactComponent as Undo } from "../../../../assets/svgIcons/lsq_undo.svg";
import Text from '../../../../components/Text';
import CustomButton from '../../../../components/CustomButton';
import styles from "./style.module.css";
import { casaWebConstants } from '../../../../constants/globalConstant';
import { useSelector } from 'react-redux';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // Set canvas size to match the bounding box
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Draw rotated image
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.translate(-canvas.width / 2, -canvas.height / 2);

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, 'image/jpeg');
  });
}

export function ImageCropper({ image, onCancel, onCropComplete }) {
  const { widgetType } = useSelector(state => state.webHomepage.selectedWidgetLanguage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [originalState] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0
  });


  const onCropCompleteCallback = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const aspectRatio = {
    "banner": 16 / 9,
    "Cover": 817 / 128
  }

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onUndo = () => {
    setCrop(originalState.crop);
    setZoom(originalState.zoom);
    setRotation(originalState.rotation);
  };

  return (
    <div className={`flex flex-column ${styles.imageCropperWrapper}`}>
      <div className="relative w-full" style={{ height: '150px ' }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspectRatio[widgetType]}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteCallback}
          onZoomChange={setZoom}
        />
      </div>

      <div className="flex align-items-center mt-3">
        <div className="flex flex-row align-items-center gap-1 flex-grow-1">
          <Text color="#283A5A" type="T4">Zoom</Text>
          <Slider
            className={`lsq-slider ${styles.sliderWrapper}`}
            value={zoom}
            onChange={(e) => setZoom(e.value)}
            step={0.1}
            min={1}
            max={3}
          />
          <Text color="#768196" type="T4">{Math.round(100 * zoom) - 100}%</Text>
        </div>

        <div className="flex align-items-center ml-2">
          <div
            className="cursor-pointer"
            onClick={() => setRotation((prev) => (prev + 90) % 360)}
          >
            <Rotate />
          </div>

          <div>
            <Undo onClick={onUndo} className="cursor-pointer" />
          </div>

        </div>
      </div>

      <div className={`flex justify-content-end gap-2 mt-3 ${styles.buttonsWrapper}`}>
        <CustomButton
          varient="outline"
          label={casaWebConstants.CANCEL}
          onClick={onCancel}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}